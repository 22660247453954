.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.li {
  height: 40vh;
  flex-grow: 1;
}

.li:last-child {
  flex-grow: 10;
}

.img {
  cursor: pointer;
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

@media (max-aspect-ratio: 1/1) {
  .li {
    height: 30vh;
  }
}
@media (max-height: 480px) {
  .li {
    height: 80vh;
  }
}
@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .ul {
    flex-direction: row;
  }

  .li {
    height: auto;
    width: 100%;
  }

  .img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
}
