.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: block;
  z-index: 999;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid rgb(0, 0, 0);
  border-right: 3px solid rgb(0, 0, 0);
  -webkit-transform: translate(-50%, 0%) rotate(225deg);
  -moz-transform: translate(-50%, 0%) rotate(225deg);
  transform: translate(-50%, 0%) rotate(225deg);
  -webkit-animation: fade_move_down 1.5s ease-in-out infinite;
  -moz-animation: fade_move_down 1.5s ease-in-out infinite;
  animation: fade_move_down 1.5s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  100% {
    -webkit-transform: translate(0, -10px) rotate(225deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  0% {
    -webkit-transform: translate(0, -10px) rotate(225deg);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  100% {
    -moz-transform: translate(0, -10px) rotate(225deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  0% {
    -moz-transform: translate(0, 10px) rotate(225deg);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  100% {
    transform: translate(0, -10px) rotate(225deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  0% {
    transform: translate(0, 10px) rotate(225deg);
    opacity: 0;
  }
}
