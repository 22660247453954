.accordion-header {
  font-family: "Courier New", Courier, monospace;
  width: 100%;
  color: black;
  background-color: inherit;
  font-family: inherit;
  justify-content: space-between;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  border: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  outline: none;
  padding: 15px 0 15px 38px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: unset;
  margin: unset;
}

.accordion-header-bold {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.accordion-panel {
  height: 0;
  overflow: hidden;
}

.accordion-header:after {
  font-family: "Courier New", Courier, monospace;
  font-size: x-large;
  color: rgb(0, 0, 0, 0.5);
  content: "^";
  transform: rotate(180deg);
  margin: 0 15px;
}

.accordion-expanded:after {
  transform: rotate(0deg);
}
